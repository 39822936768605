// CSS
import "./App.css";
import ThemeSetter from "./common/theme/components/themeshetter";
import ThemeProvider from "./common/theme/components/contexts/themeprovider";
import React from 'react';
const RestaurantMain = React.lazy(() => import('./pages/restuarant-page/main'));
const TakeawayMain = React.lazy(() => import('./pages/takeaway-page/main'));


function App({ apiResponse }) {
  return (
    <>
      <ThemeProvider>
        <ThemeSetter />
        {apiResponse === 1 ? (
          <React.Suspense fallback={<div>Loading...</div>}>
            <RestaurantMain />
          </React.Suspense>
        ) : (
          <React.Suspense fallback={<div>Loading...</div>}>
            <TakeawayMain />
          </React.Suspense>
        )}
      </ThemeProvider>
    </>
  );
}

export default App;
