// src/reducers/index.js
import { combineReducers } from 'redux';
import { homepageReducer } from "./home/homepageReducer";
import { mealDealReducer } from "./home/mealDealReducer";
import { aboutReducer } from "./home/aboutReducer";
import { galleryReducer } from "./home/galleryReducer";
import { reviewReducer } from "./home/reviewReducer";
import { signupReducer } from "./home/signupReducer";
import { footerReducer } from "./home/footerReducer";
import { tableBookingReducer } from "./home/tableBookingReducer";
import { feedBackReducer } from "./home/feedBackReducer";

const rootReducer = combineReducers({
  homepage: homepageReducer,
  mealDeal: mealDealReducer,
  about: aboutReducer,
  gallery:galleryReducer,
  review:reviewReducer,
  signup:signupReducer,
  footer:footerReducer,
  tableBooking:tableBookingReducer,
  feedBack:feedBackReducer,
});

export default rootReducer;
