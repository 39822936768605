import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from "./store";
const fetchData = async () => {
  try {
    const response = await fetch("https://api-landing.fusionkitchen.co.uk/businessType", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        domain: window.location.hostname,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data);
    return data; 
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};


const renderApp = async () => {
  try {
    const apiResponse = await fetchData();
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <App apiResponse={apiResponse?.data?.businessType} />
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  } catch (error) {
    console.error("Error rendering app:", error);
  }
};

renderApp();

reportWebVitals();
