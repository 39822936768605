
// Home page actions
export const GET_HOMEPAGE_SUCCESS = "GET_HOMEPAGE_SUCCESS";
export const GET_HOMEPAGE_FAILURE = "GET_HOMEPAGE_FAILURE";

export const GET_MEALDEAL_SUCCESS = "GET_MEALDEAL_SUCCESS";
export const GET_MEALDEAL_FAILURE = "GET_MEALDEAL_FAILURE";

export const GET_ABOUT_SUCCESS = "GET_ABOUT_SUCCESS";
export const GET_ABOUT_FAILURE = "GET_ABOUT_FAILURE";

export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_FAILURE = "GET_GALLERY_FAILURE";

export const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
export const GET_REVIEW_FAILURE = "GET_REVIEW_FAILURE";

export const GET_SIGNUP_SUCCESS = "GET_SIGNUP_SUCCESS";
export const GET_SIGNUP_FAILURE = "GET_SIGNUP_FAILURE";

export const GET_FOOTER_SUCCESS = "GET_FOOTER_SUCCESS";
export const GET_FOOTER_FAILURE = "GET_FOOTER_FAILURE";

export const GET_TABLEBOOKING_SUCCESS = "GET_TABLEBOOKING_SUCCESS";
export const GET_TABLEBOOKING_FAILURE = "GET_TABLEBOOKING_FAILURE";

export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_FAILURE = "GET_FEEDBACK_FAILURE";

